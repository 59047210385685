import React from 'react';
import { 
  Typography, Box, Paper, List, ListItem, ListItemIcon, ListItemText, 
  Card, CardHeader, CardContent, Container, Grid, useTheme, useMediaQuery
} from "@mui/material";
import { Icon } from '@iconify/react';

const LicenciasDeConducir = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tramites = [
    {
      title: 'Mi Primera Licencia',
      requirements: [
        'Ser mayor de 18 años de edad',
        'Tener domicilio en la localidad con una antigüedad mayor a 6 meses',
        'Examen Psicofisico realizado',
        'Aprobar el examen teorico',
        'Aprobar el examen practico.'
      ]
    },
    {
      title: 'Renovación de Licencia de Conducir',
      requirements: [
        'Ser mayor de 18 años de edad',
        'Tener domicilio en la localidad con una antigüedad mayor a 6 meses',
        'Examen Psicofísico realizado',
        'Aprobar el examen teórico (En caso de tener antecedentes)',
        'Aprobar el examen práctico. (En caso de tener antecedentes)',
        'No adeudar Multas a la Policía Caminera.',
        'Presentar la Licencia de conducir vencida.'
      ]
    },
    {
      title: 'Re impresión de Plástico',
      requirements: [
        'Denuncia policial de extravío.',
        'No adeudar Multas a la Policía Caminera.'
      ]
    },
    {
      title: 'Certificado de Legalidad',
      requirements: [
        'Presentar DNI',
        'Licencia vigente',
        '(Se otorga un certificado por cada categoría de licencia obtenida)'
      ]
    },
    {
      title: 'Licencia Profesional',
      requirements: [
        'No debe ser primera licencia.',
        'Ser mayor de 21 años de edad.',
        'Ser menor de 65 años de edad.',
        'Tener domicilio en la localidad con una antigüedad mayor a 6 meses',
        'Examen Psicofísico realizado',
        'Aprobar el examen teórico',
        'Aprobar el examen práctico.',
        'Certificado de antecedentes penales. (Se obtiene desde la web de la Policía de Córdoba)',
        'Certificado contra la integridad sexual. (Se obtiene a través de C.I.D.I)',
        'No adeudar Multas a la Policía Caminera.'
      ]
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
            <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
              Licencias de Conducir
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <List disablePadding>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:map-marker" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Ubicación" 
                      secondary="Edificio Municipal Av.Los Fresnos Esq. Los Aceres"
                      primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                      secondaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                    />
                  </ListItem>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:phone" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Contacto" 
                      secondary="03525-491212 / 491456"
                      primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                      secondaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 4, mt: 2, borderLeft: '8px solid #163e6a' }}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1.1rem', sm: '1.3rem' } }}>
              Horarios de Atención
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                  Para realizar examen Psicofisico: (En C.A.P.S.)
                </Typography>
                <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                  Martes y Miércoles de 08:00 a 12:00 Hs.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                  Para completar el tramite (En Edificio municipal)
                </Typography>
                <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                  Lunes, Martes, Miércoles y Viernes de 07:30 a 13:30 Hs.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ mt: 3, fontSize: { xs: '1.1rem', sm: '1.3rem' } }}>
            Trámites
          </Typography>
          <Grid container spacing={2}>
            {tramites.map((tramite, index) => (
              <Grid item xs={12} key={index}>
                <Card elevation={3} sx={{ borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
                  <CardHeader
                    title={tramite.title}
                    titleTypographyProps={{ variant: 'h6', fontSize: { xs: '1rem', sm: '1.1rem' } }}
                    sx={{ pb: 1, pt: 2, px: 2 }}
                  />
                  <CardContent sx={{ pt: 0, pb: 2, px: 2 }}>
                    <List dense>
                      {tramite.requirements.map((req, idx) => (
                        <ListItem key={idx} disablePadding>
                          <ListItemIcon sx={{ minWidth: 24 }}>
                            <Icon icon="mdi:check-circle" width="16" height="16" />
                          </ListItemIcon>
                          <ListItemText 
                            primary={req}
                            primaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LicenciasDeConducir;