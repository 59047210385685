import React from 'react';
import { 
  Typography, Box, Paper, List, ListItem, ListItemIcon, ListItemText, 
  Card, CardHeader, CardContent, Container, Grid, useTheme, useMediaQuery
} from "@mui/material";
import { Icon } from '@iconify/react';

const AccionSocial = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const areas = [
    {
      title: 'Equipo de Violencia Familiar',
      content: 'Conformado por una psicóloga y una trabajadora social: Licenciada Soledad Bonza y Licenciada Psicóloga Florencia Ferrero. Brindan atención y asesoramiento gratuito a personas que sufren violencia doméstica. Trabajan en conjunto con la Dirección de Violencia Familiar de la Provincia en el marco de la ley 9283.'
    },
    {
      title: 'Equipo Interdisciplinario Móvil',
      content: 'Conformado por profesionales de distintas ramas (Plástica, Música, Teatro, Bio danza, Cerámica), que afianzan en las escuelas las materias artísticas. Incluye taller de Fútbol para niños en las localidades de La Granja y Ascochinga.'
    },
    {
      title: 'Área de Discapacidad',
      content: 'Se focaliza en la inclusión de jóvenes con discapacidad en actividades deportivas, sociales y culturales, gestionando planes para su inclusión laboral. Cuenta con una unidad móvil de traslado totalmente equipada, disponible todo el año.'
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
            <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
              Secretaría de Desarrollo Humano y Salud
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
              La Secretaría de Desarrollo Humano y Salud cuenta con un Equipo de trabajo interdisciplinario que da respuesta a las necesidades sociales de los distintos sectores, priorizando aquellas familias que se encuentren en situación de vulnerabilidad.
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <List disablePadding>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:account" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Secretario a cargo" 
                      secondary="Sr. Marcelo Manuel Pausada"
                      primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                      secondaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                    />
                  </ListItem>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:phone" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="3525-620101" 
                      primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={6}>
                <List disablePadding>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:email" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Psicosocialeslagranja@gmail.com" 
                      secondary="Secaccionsociallagranja@gmail.com"
                      primaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                      secondaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 4, mt: 2, borderLeft: '8px solid #163e6a' }}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1.1rem', sm: '1.3rem' } }}>
              Horarios de Atención
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                  Lunes y miércoles de 8 a 13 hs
                </Typography>
                <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                  Trabajo social. Atención al público.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                  Martes y jueves de 8 a 13 hs
                </Typography>
                <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                  Equipo de niñez:
                  <ul>
                    <li>Atención de situaciones de vulneración de derechos</li>
                    <li>Talleres de promoción de derechos</li>
                    <li>Atención psicológica</li>
                    <li>Atención psicopedagógica</li>
                  </ul>
                </Typography>
                <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' }, mt: 1 }}>
                  Equipo de género y Diversidades y punto mujer:
                  <ul>
                    <li>Asistencia a mujeres en situación de violencia</li>
                    <li>Actividades de prevención y asesoramiento</li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ mt: 3, fontSize: { xs: '1.1rem', sm: '1.3rem' } }}>
            Áreas Especializadas
          </Typography>
          <Grid container spacing={2}>
            {areas.map((area, index) => (
              <Grid item xs={12} key={index}>
                <Card elevation={3} sx={{ borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
                  <CardHeader
                    title={area.title}
                    titleTypographyProps={{ variant: 'h6', fontSize: { xs: '1rem', sm: '1.1rem' } }}
                    sx={{ pb: 1, pt: 2, px: 2 }}
                  />
                  <CardContent sx={{ pt: 0, pb: 2, px: 2 }}>
                    <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                      {area.content}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AccionSocial;