import { Grid, Typography, Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import foot from "../../assets/foot.png";
import ProductService from "../../services/products.service";

export default function NewsDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [newsDetail, setNewsDetail] = useState(null);

  const fetchNewsDetail = async () => {
    try {
      const response = await ProductService.getNewsById(id);
      setNewsDetail(response);
    } catch (error) {
      console.error("Error fetching news detail:", error);
    }
  };

  useEffect(() => {
    fetchNewsDetail();
  }, [id]);

  const handleBack = () => {
    navigate("/news");
  };

  if (!newsDetail) {
    return <Typography>Cargando noticia...</Typography>;
  }

  return (
    <Grid container mt={5} justifyContent="center">
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          textAlign: "center",
          marginTop: 10,
          padding: "0 16px", // Mayor margen superior para despegar del navbar
          maxWidth: "80%", // Limitar el ancho máximo del contenedor del título
        }}
      >
        <Typography
          fontSize={{ xs: "clamp(20px, 4vw, 24px)", md: "clamp(24px, 4vw, 36px)" }}
          fontWeight={600}
          color="#fff"
          sx={{
            background: "#163e6a", // Asegurarse de que el fondo sea visible
            padding: "10px 20px", // Añadir padding para que no toque los bordes
            borderRadius: "15px",
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
            maxWidth: "100%", // Asegurarse de que el texto no se salga del contenedor
            display: "inline-block", // Hacer que el texto se ajuste a su contenido
          }}
        >
          {newsDetail.titulo}
        </Typography>
      </Grid>

      <Grid
        container
        sx={{
          marginTop: { xs: 6, md: 8 },
          padding: { xs: 2, md: 4 },
          maxWidth: "1200px",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            marginBottom: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={newsDetail.imagenes[0]}
            alt="imagen_noticia"
            style={{
              width: "100%",
              maxWidth: "600px",
              height: "auto",
              maxHeight: "400px",
              objectFit: "cover",
              borderRadius: "10px",
              boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={10}
          sx={{
            textAlign: "justify",
            fontSize: { xs: 14, md: 16 },
            lineHeight: "1.6",
            marginBottom: 2,
          }}
        >
          <Typography>
            <div
              dangerouslySetInnerHTML={{ __html: newsDetail.descripcion }}
            />
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            onClick={handleBack}
            sx={{
              backgroundColor: "#163e6a",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#163e6a",
              },
            }}
          >
            Atrás
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
