import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import {
  Box,
  Toolbar,
  IconButton,
  AppBar,
  Drawer,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../assets/logo2.png";
import Footer from "../Footer";

// Component for the links in the hamburger menu
const HamburgerNavigationLinks = ({ onLinkClick }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column", // Column layout for hamburger menu
      alignItems: "flex-start", // Align to the left
      paddingLeft: "20px", // Padding to give space from the left side
      width: "100%", // Ensure it takes full width in the Drawer
    }}
  >
    <Link
      to="/government"
      style={{
        textDecoration: "none",
        color: "#163E6A",
        marginBottom: "20px", // Space between the menu items
        fontSize: "18px",
        fontWeight: 700,
        width: "100%",
      }}
      onClick={onLinkClick}
    >
      Gobierno
    </Link>
    <Link
      to="/communication"
      style={{
        textDecoration: "none",
        color: "#163E6A",
        marginBottom: "20px", // Space between the menu items
        fontSize: "18px",
        fontWeight: 700,
        width: "100%",
      }}
      onClick={onLinkClick}
    >
      Portal de Comunicación
    </Link>
    <Link
      to="/news"
      style={{
        textDecoration: "none",
        color: "#163E6A",
        marginBottom: "20px", // Space between the menu items
        fontSize: "18px",
        fontWeight: 700,
        width: "100%",
      }}
      onClick={onLinkClick}
    >
      Noticias
    </Link>
  </Box>
);

// Component for the links in the AppBar
const AppBarNavigationLinks = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row", // Row layout for AppBar
      alignItems: "center", // Center alignment
    }}
  >
    <Link
      to="/government"
      style={{
        textDecoration: "none",
        color: "#163E6A",
        padding: "0px 10px",
        fontSize: "18px",
        fontWeight: 700,
      }}
    >
      Gobierno
    </Link>
    <Divider
      orientation="vertical"
      sx={{ height: "25px", margin: "0 10px", borderColor: "#163E6A" }}
    />
    <Link
      to="/communication"
      style={{
        textDecoration: "none",
        color: "#163E6A",
        padding: "0px 10px",
        fontSize: "18px",
        fontWeight: 700,
      }}
    >
      Portal de Comunicación
    </Link>
    <Divider
      orientation="vertical"
      sx={{ height: "25px", margin: "0 10px", borderColor: "#163E6A" }}
    />
    <Link
      to="/news"
      style={{
        textDecoration: "none",
        color: "#163E6A",
        padding: "0px 10px",
        fontSize: "18px",
        fontWeight: 700,
      }}
    >
      Noticias
    </Link>
  </Box>
);

export default function Layout({ children }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <AppBar
        position="static"
        id="appbar"
        sx={{
          position: "absolute",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderBottomLeftRadius: "50px",
          borderBottomRightRadius: "50px",
          zIndex: 10,
        }}
      >
        <Toolbar
          id="toolbar"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
            height: { xs: "80px", sm: "100px" },
          }}
        >
          {/* Logo aligned to the left with added margin for large screens */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              ml: { xs: "10px" },
            }}
          >
            <img
              src={logo}
              alt="logo"
              width={isSmallScreen ? "100px" : "120px"}
              onClick={() => {
                navigate("/");
              }}
              style={{ cursor: "pointer" }}
            />
          </Box>

          {/* AppBar Navigation Links for large screens */}
          {!isSmallScreen && (
            <Box
              sx={{
                ml: { xs: "-150px" },
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AppBarNavigationLinks />
            </Box>
          )}

          {/* Hamburger menu button for small screens */}
          {isSmallScreen && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="#163E6A"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <Icon icon="iconamoon:menu-burger-horizontal-fill" />
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for the hamburger menu */}
      <Drawer
        anchor="left"
        sx={{
          "& .MuiPaper-root": {
            width: "100%", // Full width in mobile
            height: "100%", // Full height
            backgroundColor: "rgba(225, 225, 225, 0.9)", // Semi-transparent background
            backdropFilter: "blur(8px)",
          },
        }}
        open={open}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <img
            src={logo}
            alt="logo"
            onClick={() => {
              navigate("/");
              toggleDrawer(false);
            }}
            style={{ cursor: "pointer", maxWidth: "140px" }}
          />
          <Icon
            icon="ic:round-close"
            width={30}
            height={30}
            onClick={toggleDrawer(false)}
            style={{
              color: "#163E6A",
              cursor: "pointer",
            }}
          />
        </Box>
        <Divider sx={{ borderColor: "#38B34A", marginTop: 1 }} />
        <Box sx={{ padding: "20px" }}>
          <HamburgerNavigationLinks onLinkClick={toggleDrawer(false)} />
        </Box>
      </Drawer>

      <Box container id="main">{children}</Box>

      <Footer />
    </Box>
  );
}
