import React from 'react'
import { Grid, Typography, Box, Paper, useTheme } from '@mui/material'
import { Icon } from '@iconify/react'

export default function Component() {
  const theme = useTheme()

  return (
    <Paper 
      elevation={3}
      sx={{
        bgcolor: '#F0891F',
        color: 'white',
        mt: 3,
        py: 3,
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '4px',
          background: `linear-gradient(90deg, ${theme.palette.secondary.main}, #163E6A)`,
        },
      }}
    >
      <Box 
        maxWidth="lg" 
        mx="auto"
        sx={{
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-80px',
            right: '-80px',
            width: '200px',
            height: '200px',
            background: `radial-gradient(circle, rgba(255,255,255,0.2) 0%, transparent 70%)`,
            zIndex: 0,
          },
        }}
      >
        <Grid container spacing={8} px={4}>
          {[
            [
              { icon: 'mdi:office-building', title: 'Municipalidad', number: '(03525) 491212 / (03525) 491456' },
              { 
                icon: 'mdi:phone', 
                title: 'Secretaría de turismo, cultura y deporte', 
                number: '(03525)  491456 - int. 108',
                whatsapp: '(03525) 601475'
              },
            ],
            [
              { icon: 'mdi:hospital-box', title: 'Centro de salud', number: '(03525) 498121', whatsapp: '(03525) 455050' },
              { icon: 'mdi:fire-truck', title: 'Bomberos', number: '(03525) 491389' },
            ],
            [
              { icon: 'mdi:police-badge', title: 'Policía', number: '(03525) 491881 / 101' },
              { icon: 'mdi:bus', title: 'Servicio urbano de pasajeros', number: '(0351) 7317793' },
            ],
          ].map((column, columnIndex) => (
            <Grid item xs={12} sm={4} key={columnIndex}>
              <Box component="ul" sx={{ listStyle: 'none', padding: 0, '& li': { mb: 4 } }}>
                {column.map((item, itemIndex) => (
                  <Box 
                    component="li" 
                    key={itemIndex}
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                      },
                    }}
                  >
                    <Icon 
                      icon={item.icon} 
                      style={{ 
                        marginRight: '16px',
                        fontSize: '2.5em',
                        color: '#163E6A',
                      }} 
                    />
                    <Box>
                      <Typography 
                        variant="subtitle1" 
                        sx={{ 
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography 
                        variant="body2"
                        sx={{
                          color: 'white',
                          opacity: 0.8,
                          transition: 'opacity 0.3s ease',
                          '&:hover': {
                            opacity: 1,
                          },
                        }}
                      >
                        {item.number}
                      </Typography>
                      {item.whatsapp && (
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                          <Icon 
                            icon="ic:round-whatsapp" 
                            style={{ 
                              marginRight: '8px',
                              fontSize: '1.2em',
                            }} 
                          />
                          <Typography 
                            variant="body2"
                            sx={{
                              color: 'white',
                              opacity: 0.8,
                              transition: 'opacity 0.3s ease',
                              '&:hover': {
                                opacity: 1,
                              },
                            }}
                          >
                            {item.whatsapp}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  )
}