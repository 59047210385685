import React, { useState, useEffect } from "react";
import ThemeManager from "./theme/ThemeManager";
import AppRoute from "./routes/routes";
import { Provider } from "react-redux";
import store from "./store/store";
import Layout from "./components/Layout";
import Loader from "./components/Loading";
import { Grid } from "@mui/material";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <ThemeManager>
          {loading ? (
            <Grid
              container
              id="loadingContainer"
              alignItems="center"
              justifyContent="center"
              style={{
                minHeight: "100vh",
                background: loading ? "rgb(22, 62, 106)" : "inherit"
              }}
            >
              <Loader></Loader>
            </Grid>
          ) : (
            <Layout>
              <AppRoute />
            </Layout>
          )}
        </ThemeManager>
      </Provider>
    </div>
  );
}

export default App;
