import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

const ModalGestionOnline = ({ data, open, handleClose }) => {

  const getComponent = (id) => {
    switch (id) {
      case 3:
        return <ReclamosInfoCard />;
      default:
        break;
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      id="dialog"
      sx={{
        backgroundColor: "rgba(22, 62, 106, 0.6)",
        backdropFilter: "blur(3px)",
      }}
      PaperProps={{
        sx: {
          padding: { xs: 2, sm: 4 },
          borderRadius: 6,
          backgroundColor: "#f5f5f5",
          boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2)",
          minWidth: { xs: "96%", sm: 600 },
          minHeight: 400,
          position: "relative",
        },
      }}
    >
      <Icon
        icon="ic:round-close"
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          color: "rgb(22, 62, 106)",
          cursor: "pointer",
        }}
        width={30}
        height={30}
        onClick={handleClose}
      />
      <DialogTitle
        sx={{
          pl: 0,
          fontSize: { xs: 20, sm: 30 },
          color: "#163E6A",
          fontWeight: 600,
        }}
      >
        {data.name}
      </DialogTitle>
      <Divider sx={{ borderColor: "#163E6A" }} />
      {getComponent(data.id)}
    </Dialog>
  );
};

export default ModalGestionOnline;

const CarnetInfoCard = () => {
  return (
    <Grid container item xs={12} mt={2} spacing={1}>
      <Grid item xs={12} container>
        <Grid container item xs={6} flexDirection={"column"}>
          <Typography fontSize={20} fontWeight={600}>
            Estudio psicofísico
          </Typography>
          <Typography fontSize={14} fontWeight={500}>
            Dr. Rosendo Vázquez
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={6}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography fontSize={14} fontWeight={500}>
            Lunes y Martes
          </Typography>
          <Typography>
            de <span style={{ fontSize: "20px", color: "#163E6A" }}>08.30</span>{" "}
            a <span style={{ fontSize: "20px", color: "#163E6A" }}>12</span> hs
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} mt={{ xs: "20px", sm: "10px" }}>
        <Divider width={"30%"} />
      </Grid>
      <Grid item xs={12} mt={{ xs: 2, sm: 2 }} container>
        <Grid container item xs={6} flexDirection={"column"}>
          <Typography fontSize={20} fontWeight={600}>
            Impresión de plástico
          </Typography>
          <Typography fontSize={14} fontWeight={500}>
            Edificio municipal
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={6}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography fontSize={14} fontWeight={500}>
            Lunes, Martes, Miércoles y Viernes
          </Typography>
          <Typography>
            de <span style={{ fontSize: "20px", color: "#163E6A" }}>08.30</span>{" "}
            a <span style={{ fontSize: "20px", color: "#163E6A" }}>12</span> hs
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} mt={{ xs: "20px", sm: "10px" }}>
        <Divider width={"30%"} />
      </Grid>
      <Grid item xs={12} mt={2} container>
        <Grid container item xs={6} flexDirection={"column"}>
          <Typography fontSize={20} fontWeight={600}>
            Consultas
          </Typography>
        </Grid>
        <Grid container item xs={6} justifyContent={"center"}>
          <Icon
            icon="line-md:phone-call-loop"
            width={32}
            height={32}
            style={{ color: "#163E6A", marginRight: "10px" }}
          />
          <Typography sx={{ color: "#163E6A", fontSize: "20px" }}>
            3525 449310
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ReclamosInfoCard = () => {
  const [copyButtonText, setCopyButtonText] = React.useState("Copiar");

  const handleCopy = () => {
    navigator.clipboard
      .writeText("reclamos@lagranja.gob.ar")
      .then(() => {
        setCopyButtonText("Copiado");
        setTimeout(() => {
          setCopyButtonText("Copiar");
        }, 2000);
      })
      .catch((err) => {
        console.error("Error al copiar al portapapeles: ", err);
      });
  };

  const openWhatsAppLink = () => {
    window.open("https://walink.co/e1caf7", "_blank");
  };

  return (
    <Grid container item xs={12} mt={2} spacing={4}>
      <Grid item xs={12} container>
        <Grid container mb={5} item xs={12} flexDirection={"column"}>
          <Typography fontSize={18} fontWeight={500} textAlign={{ xs: "center", sm: "left" }}>
            Puedes comunicarte con nuestra oficina de reclamos a través de nuestro Whatsapp.
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          justifyContent={"start"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
          onClick={openWhatsAppLink}
        >
          <Icon icon="logos:whatsapp-icon" width={30} height={30} />
          <Typography fontSize={18} ml={2}>
            3525 449310
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          sx={{
            mt: { xs: 2, sm: 0 }, // Aplica margin-top solo en pantallas pequeñas
          }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontSize={18} textAlign={{ xs: "center", sm: "left" }}>
            reclamos@lagranja.gob.ar
          </Typography>

          <Icon
            icon={copyButtonText === "Copiado" ? "gravity-ui:copy-check" : "gravity-ui:copy"}
            width={30}
            height={30}
            style={{
              color: copyButtonText === "Copiado" ? "green" : "#163E6A",
              cursor: "pointer",
            }}
            onClick={handleCopy}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} mt={{ xs: "20px", sm: 5 }}>
        <Divider width={"30%"} />
      </Grid>
    </Grid>
  );
};

const BoletinOficial = () => {
  //drive.google.com/file/d/1Xh_SdZkQYg_8Mzq2JxFCGV66mIFVCqqa/view?usp=drive_link

  https: return (
    <Grid container item xs={12} mt={2}>
      <Grid item xs={12} mb={1} container justifyContent={"flex-end"}>
        <Button
          variant="contained"
          href="https://drive.google.com/uc?export=download&id=1Xh_SdZkQYg_8Mzq2JxFCGV66mIFVCqqa"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textTransform: "none", backgroundColor: "#163E6A" }}
        >
          Descargar PDF
        </Button>
      </Grid>

      <Grid item xs={12} container>
        <iframe
          src="https://drive.google.com/file/d/1Xh_SdZkQYg_8Mzq2JxFCGV66mIFVCqqa/preview"
          width="100%"
          height="600px"
          style={{ border: "none" }}
          allowFullScreen
          title="Boletin Oficial"
        />
      </Grid>
    </Grid>
  );
};
