import { Icon } from "@iconify/react";
import { Grid, IconButton, InputBase, Paper, Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import foot from "../../assets/foot.png";
import PortalCommunicationCard from "../../components/PortalCommunicationCard";
import ProductService from "../../services/products.service";

const PortalCommunication = () => {
  const [allCommunications, setAllCommunications] = useState([]);
  const [filteredCommunications, setFilteredCommunications] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerPage = 6;

  const fetchCommunications = async () => {
    try {
      const response = await ProductService.getAllCommunication();
      setAllCommunications(response);
      setFilteredCommunications(response);
    } catch (error) {
      console.error("Error fetching communications:", error);
    }
  };

  useEffect(() => {
    fetchCommunications();
  }, []);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    setPage(1);

    const filtered = allCommunications.filter((card) =>
      card.titulo.toLowerCase().includes(term)
    );
    setFilteredCommunications(filtered);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const paginatedCommunications = filteredCommunications.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <Grid container justifyContent="center" sx={{ padding: 0, mt: 10 }}>
      <Grid
        sx={{
          position: "absolute",
          top: 0,
          height: "30%",
          zIndex: -300,
          backgroundImage: `url(${foot})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      />
      <Grid
        container
        justifyContent="center"
        item
        xs={12}
        md={8}
        mt={4}
        sx={{ padding: 0, maxWidth: '100%' }}
      >
        <Grid item container xs={12} justifyContent="center">
          <Grid item xs={8}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Buscar"
                inputProps={{ "aria-label": "buscar" }}
                value={searchTerm}
                onChange={handleSearch}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <Icon icon="wpf:search" />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          mt={10}
          spacing={2}
          justifyContent="center"
        >
          {paginatedCommunications.map((card) => (
            <Grid item xs={12} sm={6} md={4} key={card.id}>
              <PortalCommunicationCard data={card} />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} container justifyContent="center" mt={4} mb={4}>
          <Pagination
            count={Math.ceil(filteredCommunications.length / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
            sx={{
              '& .MuiPaginationItem-root.Mui-selected': {
                backgroundColor: '#163e6a', // Your desired background color
                color: '#ffffff', // Text color for better contrast
                '&:hover': {
                  backgroundColor: '#163e6a', // Darker shade for hover state
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PortalCommunication;