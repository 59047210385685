import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify.js";
import escudo from "../../assets/escudo.png";
import logo from "../../assets/logo.png";

export default function Footer() {
  return (
    <Grid
      container
      id="footerContainer"
      sx={{
        width: "100%",
        maxHeight: "200px",
        background: "#163E6A",
        color: "#fff",
        padding: "20px",
      }}
    >
      <Grid item xs={6} container alignItems={'center'}>
        <Typography variant="body2" align="start">
       
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent={"end"} alignItems={'center'}>
        <img src={logo} alt="escudo" style={{height: '80px', }} />
        <Divider 
          orientation="vertical" 
          flexItem 
          sx={{
            backgroundColor: '#FDB714',
            width: '2px',
            boxShadow: '0px 0px 10px 1px rgba(253,183,20,0.7)',
            margin: '0 16px'
          }}
        />
        <img src={escudo} alt="escudo" style={{height: '100px'}} />
      </Grid>
    </Grid>
  );
}