import React from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function ThemeManager({ children }) {
  let theme = createTheme({
    palette: {
      primary: {
        main: "#404040",
      },
      secondary: {
        main: "#2E677A",
      },
    },
    typography: {
      fontFamily: "Tahoma", // Especificar la fuente personalizada
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'primary' },
            style: {
              textTransform: "none",
              backgroundColor: "#38B34A",
              color: "white",
              padding: "10px 30px",
              fontWeight: 600,
              borderRadius: "8px",
              ':hover': {
                backgroundColor:"#036B39"
              }
            },
          },
          {
            props: { variant: 'secondary' },
            style: {
              textTransform: "none",
              backgroundColor: "#2E677A",
              color: "white",
              padding: "10px 20px",
              borderRadius: "16px",
            },
          },
          {
            props: { variant: 'tertiary' },
            style: {
              textTransform: "none",
              backgroundColor: "#404040",
              color: "white",
              padding: "5px 15px",
              borderRadius: "4px",
            },
          },
        ],
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
