import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
  Container,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import foot from "../../assets/banner1.png";
import RegistroCivil from "./registroCivil";
import AccionSocial from "./accionSocial";
import LicenciasDeConducir from "./carnet";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: { xs: 2, md: 3 } }}>{children}</Box>}
    </div>
  );
}

const GuiaDeTramites = () => {
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tabsStyles = {
    "& .MuiTab-root": {
      "&:hover": {
        backgroundColor: "transparent",
        fontWeight: "bold",
      },
      "&.Mui-selected": {
        fontWeight: "bold",
        color: "#163e6a",
      },
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#163e6a",
    },
  };

  return (
    <Grid container mt={5} justifyContent="center">
      {/* Imagen de fondo */}
      <Grid
        sx={{
          position: "absolute",
          top: 0,
          height: { xs: "300px", md: "400px" },
          zIndex: -300,
          backgroundImage: `url(${foot})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        {/* Barras de colores en el pie */}
        <Grid container sx={{ position: "absolute", bottom: 0, height: "10px" }}>
          <Grid item xs={4} sx={{ backgroundColor: "#163E6A" }} />
          <Grid item xs={4} sx={{ backgroundColor: "#F0891F" }} />
          <Grid item xs={4} sx={{ backgroundColor: "#036B39" }} />
        </Grid>
      </Grid>

      {/* Título */}
      <Grid item xs={12} mt={{ xs: "200px", md: "277px" }} textAlign="center">
        <Typography
          fontSize={{ xs: 24, md: 36 }}
          fontWeight={600}
          color={"#fff"}
        >
          Guía de Trámites
        </Typography>
      </Grid>

      {/* Tabs y contenido */}
      <Grid
        item
        xs={12}
        mt={10}
        mb={10}
        justifyContent="center"
        sx={{
          minHeight: { xs: "calc(100vh - 300px)", md: "calc(100vh - 400px)" }, // Altura dinámica según el viewport
        }}
      >
        <Container maxWidth="lg">
          <Paper elevation={3} sx={{ borderRadius: 4, overflow: "hidden" }}>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
                display: "flex",
                flexDirection: { xs: "column", md: "row" }, // Cambia a columna en móviles
                minHeight: { xs: "auto", md: "calc(100vh - 400px)" },
                marginBottom: { xs: 4, md: 0 }, // Ajuste de espacio en pantallas pequeñas
              }}
            >
              <Tabs
                orientation={isDesktop ? "vertical" : "horizontal"} // Cambia la orientación según el tamaño de pantalla
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Guía de Trámites tabs"
                sx={{
                  ...(isDesktop
                    ? {
                        borderRight: 1,
                        borderColor: "divider",
                        minWidth: "200px",
                        "& .MuiTabs-indicator": {
                          left: 0,
                          right: "auto",
                        },
                      }
                    : {
                        borderBottom: 1,
                        borderColor: "divider",
                        "& .MuiTabs-flexContainer": {
                          flexDirection: "column", // Establece la dirección vertical en pantallas pequeñas
                        },
                        "& .MuiTab-root": {
                          flexGrow: 1, // Cada tab ocupa el ancho completo
                        },
                      }),
                  ...tabsStyles,
                }}
              >
                <Tab label="Registro Civil" />
                <Tab label="Carnet de Conducir" />
                <Tab label="Secretaría de Desarrollo Humano y Salud" />
              </Tabs>
              <Box sx={{ flexGrow: 1, overflow: "auto" }}>
                <TabPanel value={value} index={0}>
                  <RegistroCivil />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <LicenciasDeConducir />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <AccionSocial />
                </TabPanel>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Grid>
    </Grid>
  );
};

export default GuiaDeTramites;
