import { sections } from "./constants";
import { Icon } from "@iconify/react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
  Link,
  Divider,
  Button,
  Fab,
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  List,
  ListItemButton,
  ListItemText 
} from "@mui/material";
import { useState } from "react";
import "./styles.css";

const ProfileCard = ({
  name,
  role,
  phone,
  email,
  image,
  subordinates,
  id,
  level = 0,
}) => {
  const [expanded, setExpanded] = useState(level === 1);

  const handleExpandClick = () => {
    if (level !== 0) {
      setExpanded(!expanded);
    }
  };

  const backgroundColors = ["#fff", "#f0f4ff", "#e8f5e9", "#fbe9e7", "#fff9c4"];
  const backgroundColor = backgroundColors[level % backgroundColors.length];

  return (
    <>
      <Card
        id={id}
        sx={{
          padding: { xs: 1.5, sm: 3 },
          borderRadius: "16px",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
          marginBottom: "16px",
          backgroundColor: backgroundColor,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Avatar
            src={image}
            sx={{
              marginRight: { xs: 0, sm: "24px" },
              width: 100,
              height: 100,
              border: "3px solid #3f51b5",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          />
          <CardContent sx={{ flexGrow: 1, textAlign: { xs: "center", sm: "left" } }}>
            <Typography
              component="div"
              variant="h5"
              sx={{ fontWeight: "bold", color: "#333" }}
            >
              {name}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "#163e69", fontSize: "1.1rem" }}
            >
              {role}
            </Typography>
          </CardContent>
        </Box>

        <Divider sx={{ marginY: { xs: 0.5, sm: 2 } }} />

        <CardContent>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              container
              sx={{ justifyContent: { xs: "center", sm: "initial" } }}
            >
              <Typography
                variant="body2"
                sx={{ marginBottom: "8px", fontSize: { xs: "14px", sm: "14px" } }}
              >
                📞 <strong>Teléfono:</strong> {phone}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              container
              sx={{ justifyContent: { xs: "center", sm: "initial" } }}
            >
              <Typography
                variant="body2"
                sx={{ marginBottom: "16px", fontSize: { xs: "14px", sm: "14px" } }}
              >
                ✉️ <strong>Email:</strong>{" "}
                <Link href={`mailto:${email}`}>{email}</Link>
              </Typography>
            </Grid>
          </Grid>

          {subordinates && subordinates.length > 0 && (
            <>
              <Divider sx={{ marginY: { xs: 0.5, sm: 2 } }} />
              {level !== 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "24px",
                  }}
                >
                  <Button
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    endIcon={
                      <Icon
                        icon={expanded ? "mdi:minus" : "mdi:plus"}
                        className={`expand-icon ${expanded ? "expanded" : ""}`}
                      />
                    }
                    sx={{
                      color: "#163e69",
                      "&:hover": {
                        backgroundColor: "#163e69",
                        color: "#fff",
                      },
                    }}
                  >
                    {expanded ? "Contraer" : "Expandir"}
                  </Button>
                </Box>
              )}
            </>
          )}
        </CardContent>
      </Card>

      {(expanded || level === 0) && (
        <Box sx={{ marginLeft: { xs: 2, sm: 4 } }}>
          {subordinates.map((subordinate) => (
            <ProfileCard key={subordinate.id} {...subordinate} level={level + 1} />
          ))}
        </Box>
      )}
    </>
  );
};

const SidebarNavigation = ({ sections, onClose }) => {
  const handleScrollToCard = (id) => {
    const cardElement = document.getElementById(id);
    if (cardElement) {
      cardElement.scrollIntoView({ behavior: "smooth" });
      cardElement.classList.add("highlight");
      setTimeout(() => {
        cardElement.classList.remove("highlight");
      }, 1500);
    }
    if (onClose) onClose();
  };

  const renderItemsWithSubordinates = (item, level = 1) => {
    if (!item.showInMenu) return null;

    return (
      <div key={item.id}>
        <Typography
          variant="subtitle1"
          sx={{
            marginTop: level === 1 ? 2 : 1,
            marginBottom: 1,
            fontWeight: "bold",
            color: "#3f51b5",
          }}
        >
          {item.role}
        </Typography>

        <ListItemButton
          component="a"
          onClick={() => handleScrollToCard(item.id)}
          className="list-item-button"
        >
          <ListItemText primary={item.name} secondary={item.phone} />
        </ListItemButton>

        {item.subordinates && item.subordinates.length > 0 && (
          <List component="div" disablePadding>
            {item.subordinates.map((subordinate) =>
              renderItemsWithSubordinates(subordinate, level + 1)
            )}
          </List>
        )}
      </div>
    );
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom className="menu-title">
        Índice
      </Typography>
      <List>
        {sections.map((section, index) => (
          <div key={index}>
            {section.items.map((item) => renderItemsWithSubordinates(item))}
          </div>
        ))}
      </List>
    </div>
  );
};

const Organigrama = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpenSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  return (
    <Grid container spacing={2} sx={{ justifyContent: { xs: "center", sm: "initial" } }}>
      {!isMobile && (
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#fff",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              p: 2,
            }}
          >
            <SidebarNavigation sections={sections} />
          </Box>
        </Grid>
      )}

      <Grid item xs={12} md={9} container justifyContent="center">
        <Box sx={{ width: "100%", maxWidth: "800px" }}>
          {sections.map((section) =>
            section.items.map((profile) => (
              <ProfileCard key={profile.id} {...profile} />
            ))
          )}
        </Box>
      </Grid>

      {isMobile && (
        <>
          <Fab
            color="primary"
            aria-label="add"
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
              backgroundColor: "#163e6a",
            }}
            onClick={handleOpenSidebar}
          >
            <Icon icon="mdi:menu" color="#fff" />
          </Fab>

          <Dialog open={openSidebar} onClose={handleCloseSidebar} fullWidth maxWidth="xs">
            <DialogContent>
              <SidebarNavigation sections={sections} onClose={handleCloseSidebar} />
            </DialogContent>
          </Dialog>
        </>
      )}
    </Grid>
  );
};

export default Organigrama;
