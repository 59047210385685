import React from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import NewsDetail from "../pages/NewsDetail/index.jsx";
import NewsPage from "../pages/NewsPage/index.jsx";
import Government from "../pages/Government/Government.jsx";
import PortalCommunication from "../pages/PortalCommunication/index.jsx";
import GuiaDeTramites from "../pages/GuiaDeTramites/index.jsx";
import Turismo from "../pages/Turismo/index.jsx";
import Transporte from "../pages/Transporte/index.jsx";
import CentroDeSalud from "../pages/CentroDeSalud/index.jsx";
import Vecino from "../pages/Vecino/index.jsx";
import Boletin from "../pages/Boletin/index.jsx";

// Función para verificar si el usuario está autenticado
function isAuthenticated() {
  const token = localStorage.getItem("token");
  return !!token; // Devuelve true si hay un token, false en caso contrario
}

const PageLayout = ({ redirectPath = "/login" }) => {
  // if (!isAuthenticated()) {
  //   return <Navigate to={redirectPath} replace />;
  // }

  return (
    <>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
    </>
  );
};

export default function AppRoute() {
  return (
    <Routes>
      {/* <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} /> */}
      <Route path="/" element={<Home />} />
      <Route path="/government" element={<Government />} />
      <Route path="/communication" element={<PortalCommunication />} />
      <Route path="/news" element={<NewsPage />} />
      <Route path="/tramites" element={<GuiaDeTramites />} />
      <Route path="/turismo" element={<Turismo />} />
      <Route path="/transporte" element={<Transporte />} />
      <Route path="/salud" element={<CentroDeSalud />} />
      <Route path="/vecino" element={<Vecino />} />
      <Route path="/boletin" element={<Boletin />} />
      <Route path="/news/:id" element={<NewsDetail />} />
    </Routes>
  );
}
