import React from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardContent, CardActions, Typography, Box } from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: '350px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1),
  color: '#141417',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.03)',
    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
  },
}));

const CardHero = styled(CardContent)(({ theme }) => ({
  backgroundColor: 'rgb(22, 62, 106)',
  borderRadius: `${theme.spacing(2)} ${theme.spacing(2)}`,
  padding: theme.spacing(2),
  flexGrow: 1,
}));

const CardImage = styled('img')({ 
  width: '100%',
  height: '250px',
  borderRadius: '8px',
  marginBottom: '0.5rem',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const CardTitle = styled(Typography)(({ theme }) => ({
  margin: '0.5rem 0',
  color: '#fff',
  fontSize: '1rem',
  fontWeight: 600,
  lineHeight: 1.2,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1rem',
  },
}));

const CardFooter = styled(CardActions)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(1.5),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  '& *': {
    fontSize: '0.875rem',
    lineHeight: 1.4,
    margin: 0,
    padding: 0,
  },
  '& h1, & h2, & h3, & h4, & h5, & h6': {
    fontSize: '1rem',
    fontWeight: 600,
    marginBottom: '0.5rem',
  },
  '& p': {
    marginBottom: '0.5rem',
  },
  [theme.breakpoints.up('sm')]: {
    '& *': {
      fontSize: '1rem',
    },
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      fontSize: '1.125rem',
    },
  },
}));

export default function PortalCommunicationCard({ data }) {
  return (
    <StyledCard tabIndex={0}>
      <CardHero>
        <CardImage 
          src={data.imagenes[0] || '/placeholder.svg?height=200&width=300'} 
          alt={data.titulo || 'Card image'}
        />
        <CardTitle variant="h2">{data.titulo || 'No Title'}</CardTitle>
      </CardHero>
    </StyledCard>
  );
}