import { useState } from "react";
import { Grid, Box, Tabs, Tab, useMediaQuery, useTheme } from "@mui/material";
import foot from "../../assets/foot.png";
import "./styles.css";
import Organigrama from "./Organigrama";
import ConsejoDeliberante from "../GuiaDeTramites/consejoDeliberante";
import JuzgadoDePazCard from "../GuiaDeTramites/juezDePaz";
import TribunalDeCuentas from "./Tribunal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function Government() {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabs = [
    { label: "Organigrama", content: <Organigrama /> },
    { label: "Concejo deliberante", content: <ConsejoDeliberante /> },
    { label: "Juez de paz", content: <JuzgadoDePazCard /> },
    { label: "Tribunal de cuentas", content: <TribunalDeCuentas /> }
  ];

  return (
    <Grid container justifyContent="center" sx={{ padding: 0, mt: 10 }}>
      <Grid
        sx={{
          position: "absolute",
          top: 0,
          height: "30%",
          zIndex: -300,
          backgroundImage: `url(${foot})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      />

      <Grid
        container
        justifyContent="center"
        item
        xs={12}
        sm={11}
        md={10}
        mt={25}
        sx={{ padding: 0 }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="government tabs"
              TabIndicatorProps={{
                style: { backgroundColor: '#163e6a', border: '2px' }
              }}
              sx={{
                '& .MuiTabs-flexContainer': {
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                },
                '& .MuiTab-root': {
                  padding: { xs: '12px 16px', sm: '12px 24px' },
                },
              }}
            >
              {tabs.map((tab, index) => (
                isMobile && index > 1 ? null : (
                  <Tab key={index} label={tab.label} />
                )
              ))}
            </Tabs>
            {isMobile && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                {tabs.slice(2).map((tab, index) => (
                  <Tab 
                    key={index + 2}
                    label={tab.label} 
                    sx={{ 
                      marginTop: 2,
                      minHeight: 48,
                      borderBottom: tabValue === index + 2 ? '2px solid #163e6a' : 'none',
                      flex: '1 0 50%',
                    }}
                    onClick={() => handleTabChange(null, index + 2)}
                  />
                ))}
              </Box>
            )}
          </Box>
          {tabs.map((tab, index) => (
            <TabPanel key={index} value={tabValue} index={index}>
              {tab.content}
            </TabPanel>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}