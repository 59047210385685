import React from 'react';
import { 
  Typography, Paper, List, ListItem, ListItemIcon, ListItemText, 
  Container, Grid, useTheme, useMediaQuery
} from "@mui/material";
import { Icon } from '@iconify/react';

const JuzgadoDePazCard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
            <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
              Juzgado de Paz
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <List disablePadding>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:account-tie" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Juez de paz" 
                      secondary="Blanca Fassi"
                      primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                      secondaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                    />
                  </ListItem>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:clock-outline" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Horario de Atención" 
                      secondary="Miércoles de 8:30 a 12:30"
                      primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                      secondaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={6}>
                <List disablePadding>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:phone" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Teléfono" 
                      secondary="351-2437822"
                      primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                      secondaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                    />
                  </ListItem>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:map-marker" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Dirección" 
                      secondary="Ruta E-53 s/n. (Al Lado Honorable Concejo Deliberante)"
                      primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                      secondaryTypographyProps={{ 
                        fontSize: { xs: '0.8rem', sm: '0.9rem' },
                        sx: { wordBreak: 'break-word' }
                      }}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default JuzgadoDePazCard;