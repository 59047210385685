import axiosInstance from './utils/axiosInstance';

const getAllNews = async () => {
  try {
    const response = await axiosInstance.get('/news');
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getAllCommunication = async () => {
  try {
    const response = await axiosInstance.get('/communication');
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getNewsById = async (id) => {
  try {
    const response = await axiosInstance.get(`/news/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createProduct = async (productData) => {
  try {
    const response = await axiosInstance.post("/api/products", productData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}


const updateProduct = async (id, data) => {
  try {
    const response = await axiosInstance.put(`/api/products/${id}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteProduct = async (id) => {
  try {
    const response = await axiosInstance.delete(`/api/products/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const ProductService = {
  createProduct,
  getAllCommunication,
  getNewsById,
  updateProduct,
  deleteProduct,
  getAllNews 
};

export default ProductService;
