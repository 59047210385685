import React from 'react';
import { 
  Typography, Box, Paper, List, ListItem, ListItemIcon, ListItemText, 
  Card, CardHeader, CardContent, Container, Grid, useTheme, useMediaQuery
} from "@mui/material";
import { Icon } from '@iconify/react';

const collectionSchedule = [
  {
    title: 'LUNES, MIÉRCOLES Y VIERNES',
    items: [
      'Villa Ani mi (Barrio Colonial, Barrio la Quebrada hasta Calle el Parral)',
      'Villa Ani Mi Este hasta Martin Fierro',
      'Barrio el Aguaribay',
      'La Granja - La Granja (Sindicato del Seguro)',
      'Resto de La Granja'
    ],
  },
  {
    title: 'MARTES, JUEVES Y SÁBADOS',
    items: [
      'Piquillin y Parral',
      'Todas las Vertientes y Ascochinga',
      'Calle Liquidambar y pasaje Crespon',
      'Barrio Colinas',
      'Los Molles (excepto sábados)',
      'Barrio La Primavera (excepto sábados)',
      'La Granja Centro'
    ],
  },
  {
    title: 'RECOLECCIÓN DE VERDE',
    items: [
      'Martes y Jueves',
      '(RECUERDE SR. VECINO QUE EL CESPED DEBE ESTAR EN BOLSAS PARA SU RETIRO)'
    ],
  },
  {
    title: 'RECOLECCIÓN DE DESCACHARRO',
    items: [
      'Lunes y Viernes'
    ],
  },
];

export default function WasteCollection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
            <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
              Recolección de Residuos
            </Typography>
            <List disablePadding>
              <ListItem sx={{ px: 0 }}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <Icon icon="mdi:truck" width="20" height="20" />
                </ListItemIcon>
                <ListItemText 
                  primary="Servicio de recolección de residuos" 
                  primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                />
              </ListItem>
              <ListItem sx={{ px: 0 }}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <Icon icon="mdi:recycle" width="20" height="20" />
                </ListItemIcon>
                <ListItemText 
                  primary="Próximamente: Recolección diferenciada" 
                  primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ mt: 3, fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
            Cronograma de Recolección
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card elevation={3} sx={{ borderRadius: 4, borderLeft: '8px solid #163e6a', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardHeader
                  title="LUNES, MIÉRCOLES Y VIERNES"
                  titleTypographyProps={{ variant: 'h6', fontSize: { xs: '1rem', sm: '1.1rem' } }}
                  sx={{ pb: 1, pt: 2, px: 2 }}
                />
                <CardContent sx={{ pt: 0, pb: 2, px: 2, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                  <List dense disablePadding sx={{ flexGrow: 1 }}>
                    {collectionSchedule.find(s => s.title === 'LUNES, MIÉRCOLES Y VIERNES').items.map((item, index) => (
                      <ListItem key={index} disableGutters>
                        <ListItemText 
                          primary={item} 
                          primaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card elevation={3} sx={{ borderRadius: 4, borderLeft: '8px solid #163e6a', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardHeader
                  title="MARTES, JUEVES Y SÁBADOS"
                  titleTypographyProps={{ variant: 'h6', fontSize: { xs: '1rem', sm: '1.1rem' } }}
                  sx={{ pb: 1, pt: 2, px: 2 }}
                />
                <CardContent sx={{ pt: 0, pb: 2, px: 2, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                  <List dense disablePadding sx={{ flexGrow: 1 }}>
                    {collectionSchedule.find(s => s.title === 'MARTES, JUEVES Y SÁBADOS').items.map((item, index) => (
                      <ListItem key={index} disableGutters>
                        <ListItemText 
                          primary={item} 
                          primaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card elevation={3} sx={{ mb: 2, borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
                <CardHeader
                  title="RECOLECCIÓN DE VERDE"
                  titleTypographyProps={{ variant: 'h6', fontSize: { xs: '1rem', sm: '1.1rem' } }}
                  sx={{ pb: 1, pt: 2, px: 2 }}
                />
                <CardContent sx={{ pt: 0, pb: 2, px: 2 }}>
                  <List dense disablePadding>
                    {collectionSchedule.find(s => s.title === 'RECOLECCIÓN DE VERDE').items.map((item, index) => (
                      <ListItem key={index} disableGutters>
                        <ListItemText 
                          primary={item} 
                          primaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card elevation={3} sx={{ mb: 2, borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
                <CardHeader
                  title="RECOLECCIÓN DE DESCACHARRO"
                  titleTypographyProps={{ variant: 'h6', fontSize: { xs: '1rem', sm: '1.1rem' } }}
                  sx={{ pb: 1, pt: 2, px: 2 }}
                />
                <CardContent sx={{ pt: 0, pb: 2, px: 2 }}>
                  <List dense disablePadding>
                    {collectionSchedule.find(s => s.title === 'RECOLECCIÓN DE DESCACHARRO').items.map((item, index) => (
                      <ListItem key={index} disableGutters>
                        <ListItemText 
                          primary={item} 
                          primaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2, mt: 2, borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
            <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
              Para más información sobre el servicio de recolección de residuos, por favor contacte a la oficina municipal.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}