import React, { useEffect } from "react";
import { 
  Grid, 
  Typography, 
  Paper, 
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Icon } from '@iconify/react';
import foot from "../../assets/banner2.png";

const CentroDeSalud = () => {
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const schedules = [
    {
      title: "Médico Clínico",
      schedules: [
        { days: "Lunes a Viernes", hours: "8:30 a 13:00 y 14:00 a 20:00" },
        { days: "Sábado, Domingos y Feriados", hours: "8:30 a 20:30" }
      ]
    },
    {
      title: "Servicio de Enfermería",
      schedules: [
        { days: "Lunes a Viernes", hours: "7:30 a 20:30" },
        { days: "Sábado, Domingo y Feriados", hours: "8:30 a 20:30" }
      ]
    }
  ];

  const specialties = [
    { name: "Ginecología", schedule: "Miércoles y Viernes de 14:00 a 17:00 (Solo con turno programado.)" },
    { name: "Fonoaudiología", schedule: "Martes de 08:00 a 12:00 con turno programado" },
    { name: "Cardiología", schedule: "Primer y Tercer sábado del mes (Solo con turno programado.)" },
    { name: "Oftalmología", schedule: "Segundo y Cuarto sábado del mes con (Solo con turno programado.)" },
    { name: "Consejería en Salud Sexual y Reproductiva", schedule: "Jueves de 12:00 a 16:00 con turno programado." },
    { name: "Pediatría", schedule: [
      "Lunes, Jueves y Viernes de 10:00 a 16:00 (con turno a partir de las 10:00)",
      "Martes y Miércoles de 8:00 a 12:00 con turnos a partir de las 08:00"
    ]},
    { name: "Odontología", schedule: [
      "Lunes y jueves de 08:30 a 13:00 turnos a partir de las 07:30",
      "Lunes y Jueves de 16:00 a 20:00 turnos a partir de las 14:00",
      "Miércoles 15:30 turnos a partir de las 12"
    ]},
    { name: "Vacunatorio", schedule: [
      "Lunes de 13:30 a 18:30",
      "Miércoles de 08:30 a 15:00",
      "Jueves de 14:00 a 16:00",
      "Viernes de 08:00 a 13:00",
    ]},
    { name: "Psicología Consultorio Primera Escucha", schedule: [
      "Lunes de 08:00 a 12:00",
    ]}
  ];

  const ScheduleItem = ({ days, hours }) => (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
      <Box display="flex" alignItems="center">
        <Icon icon="mdi:calendar" width="20" height="20" style={{ marginRight: '8px' }} />
        <Typography variant="body2">{days}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Icon icon="mdi:clock-outline" width="20" height="20" style={{ marginRight: '8px' }} />
        <Typography variant="body2">{hours}</Typography>
      </Box>
    </Box>
  );

  return (
    <Grid container mt={5} justifyContent="center">
      {/* Imagen de fondo */}
      <Grid
        sx={{
          position: "absolute",
          top: 0,
          height: { xs: "200px", md: "400px" }, // Ajuste de altura en pantallas pequeñas
          zIndex: -300,
          backgroundImage: `url(${foot})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        {/* Barras de colores en el pie */}
        <Grid
          container
          sx={{ position: "absolute", bottom: 0, height: "10px" }}
        >
          <Grid item xs={4} sx={{ backgroundColor: "#163E6A" }} />
          <Grid item xs={4} sx={{ backgroundColor: "#F0891F" }} />
          <Grid item xs={4} sx={{ backgroundColor: "#036B39" }} />
        </Grid>
      </Grid>

      {/* Título principal */}
      <Grid
        item
        xs={10}
        lg={8}
        mt={{ xs: "100px", md: "277px" }} // Ajustamos el margen superior en móviles
        height="fit-content"
        justifyContent="center"
      >
        <Typography
          textAlign="center"
          fontSize={{ xs: 24, md: 36 }} // Tamaño de fuente ajustado en móviles
          fontWeight={600}
          color="#fff"
        >
          Centro de Salud
        </Typography>
      </Grid>

      {/* Contenido de horarios y especialidades */}
      <Grid
        item
        xs={10}
        lg={8}
        height="fit-content"
        mt={10}
        mb={2}
        justifyContent="center"
      >
        <Typography variant="h6" gutterBottom sx={{ mt: 3, fontSize: { xs: '1.1rem', sm: '1.3rem' } }}>
          Horarios y Atenciones del CAPS
        </Typography>
        <Grid container spacing={2}>
          {schedules.map((service, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card elevation={3} sx={{ height: '100%', borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
                <CardHeader
                  title={service.title}
                  titleTypographyProps={{ variant: 'h6', fontSize: { xs: '1rem', sm: '1.1rem' } }}
                />
                <CardContent>
                  {service.schedules.map((schedule, idx) => (
                    <React.Fragment key={idx}>
                      <ScheduleItem days={schedule.days} hours={schedule.hours} />
                      {idx < service.schedules.length - 1 && <Divider sx={{ my: 1 }} />}
                    </React.Fragment>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Especialidades */}
        <Typography variant="h6" gutterBottom sx={{ mt: 3, fontSize: { xs: '1.1rem', sm: '1.3rem' } }}>
          Especialidades
        </Typography>
        <Grid container spacing={2}>
          {specialties.map((specialty, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card elevation={3} sx={{ height: '100%', borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
                <CardHeader
                  title={specialty.name}
                  titleTypographyProps={{ variant: 'h6', fontSize: { xs: '1rem', sm: '1.1rem' } }}
                />
                <CardContent>
                  {Array.isArray(specialty.schedule) ? (
                    specialty.schedule.map((schedule, idx) => (
                      <React.Fragment key={idx}>
                        <Typography variant="body2">{schedule}</Typography>
                        {idx < specialty.schedule.length - 1 && <Divider sx={{ my: 1 }} />}
                      </React.Fragment>
                    ))
                  ) : (
                    <Typography variant="body2">{specialty.schedule}</Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CentroDeSalud;
