import React from 'react';
import { 
  Typography, Box, Paper, List, ListItem, ListItemIcon, ListItemText, 
  Card, CardHeader, CardContent, Container, Grid, useTheme, useMediaQuery
} from "@mui/material";
import { Icon } from '@iconify/react';

const miembros = [
  { 
    nombre: 'Julieta Moyano', 
    cargo: 'Presidente', 
    tel: '3513862872', 
    mail: '', 
    partido: 'Juntos por el cambio'
  },
  { 
    nombre: 'Luis Roberto Heredia', 
    cargo: 'Vocal Vicepresindente', 
    tel: '3515175157', 
    mail: '', 
    partido: 'Juntos por el cambio'
  },
  { 
    nombre: 'Elvio Julio Barbieri', 
    cargo: 'Vocal', 
    tel: '3525415087', 
    mail: '', 
    partido: 'Hacemos por Córdoba'
  },
];

const TribunalDeCuentas = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
            <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
              TRIBUNAL DE CUENTAS DE LA MUNICIPALIDAD DE LA GRANJA
            </Typography>
            <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
              El Tribunal de Cuentas es el órgano de control externo del municipio encargado de fiscalizar la gestión financiera, presupuestaria y patrimonial de la administración pública.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ mt: 3, fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
            Miembros del Tribunal de Cuentas
          </Typography>
          <Box sx={{ 
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
            gap: 2
          }}>
            {miembros.map((miembro, index) => (
              <Card key={index} elevation={3} sx={{ borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
                <CardHeader
                  title={miembro.nombre}
                  subheader={miembro.cargo}
                  titleTypographyProps={{ variant: 'h6', fontSize: { xs: '1rem', sm: '1.1rem' } }}
                  subheaderTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                  sx={{ pb: 1, pt: 2, px: 2 }}
                />
                <CardContent sx={{ pt: 0, pb: 2, px: 2 }}>
                  <List dense disablePadding>
                    <ListItem disableGutters>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <Icon icon="mdi:phone" width="20" height="20" />
                      </ListItemIcon>
                      <ListItemText 
                        primary={miembro.tel} 
                        primaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                      />
                    </ListItem>
                    {miembro.mail && (
                      <ListItem disableGutters>
                        <ListItemIcon sx={{ minWidth: 36 }}>
                          <Icon icon="mdi:email" width="20" height="20" />
                        </ListItemIcon>
                        <ListItemText 
                          primary={miembro.mail} 
                          primaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                        />
                      </ListItem>
                    )}
                    <ListItem disableGutters>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <Icon icon="mdi:flag" width="20" height="20" />
                      </ListItemIcon>
                      <ListItemText 
                        primary={miembro.partido} 
                        primaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TribunalDeCuentas;