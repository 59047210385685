import React from 'react';
import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useMediaQuery } from "@mui/material";
import { useTheme, styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
import foot from "../../assets/munibus.jpeg";
import { useEffect } from "react";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  borderRadius: '16px',
  '& .MuiTableCell-root': {
    borderColor: theme.palette.divider,
  },
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& .MuiTableCell-head': {
    backgroundColor: '#163e6a',
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Transporte = () => {
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  const formatCellContent = (content, isFirstColumn) => {
    if (isMobile && isFirstColumn) {
      return toTitleCase(content);
    }
    return content;
  };

  const ScheduleTable = ({ title, headers, data }) => (
    <StyledTableContainer>
      <Table stickyHeader size={isMobile ? "small" : "medium"}>
        <StyledTableHead>
          <TableRow>
            <TableCell 
              colSpan={headers.length} 
              align="center"
              style={{ 
                position: isMobile ? 'sticky' : 'static',
                left: 0,
                zIndex: 2,
                backgroundColor: '#163e6a',
              }}
            >
              <Typography variant={isMobile ? "subtitle1" : "h6"}>
                {title}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell 
                key={index}
                style={{ 
                  position: isMobile && index === 0 ? 'sticky' : 'static',
                  left: 0,
                  zIndex: 1,
                  backgroundColor: '#F0891F',
                }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <StyledTableRow key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <TableCell 
                  key={cellIndex}
                  style={{ 
                    position: isMobile && cellIndex === 0 ? 'sticky' : 'static',
                    left: 0,
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  {formatCellContent(cell, cellIndex === 0)}
                </TableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );

  return (
    <Grid container mt={5} justifyContent="center">
      <Grid
        sx={{
          position: "absolute",
          top: 0,
          height: "400px",
          zIndex: -300,
          backgroundImage: `url(${foot})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        <Grid
          container
          sx={{ position: "absolute", bottom: 0, height: "10px" }}
        >
          <Grid item xs={4} sx={{ backgroundColor: "#163E6A" }} />
          <Grid item xs={4} sx={{ backgroundColor: "#F0891F" }} />
          <Grid item xs={4} sx={{ backgroundColor: "#036B39" }} />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        mt="277px"
        height="fit-content"
        justifyContent="center"
      >
        <Typography
          textAlign="center"
          fontSize={isMobile ? 28 : 36}
          fontWeight={600}
          color="#fff"
        >
          Transporte Público
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        height="fit-content"
        mt={10}
        justifyContent="center"
        px={isMobile ? 1 : 2}
      >
        <Typography variant={isMobile ? "h5" : "h4"} align="center" gutterBottom>
          Horarios Munibus La Granja
        </Typography>
        <Grid container spacing={2} justifyContent={'center'}>
          <Grid item xs={12} md={6}>
            <ScheduleTable
              title="SERVICIO 1 IDA LUNES A VIERNES"
              headers={["PARADA", "1ª VUELTA", "2ª VUELTA", "3ª VUELTA"]}
              data={[
                ["LAS VERTIENTES", "06:50", "11:05", "14:15"],
                ["ALAMO Y E-53 VILLA ANI MI", "07:25", "11:40", "14:50"],
                ["MARTIN FIERRO", "07:32", "11:47", "14:57"],
                ["COLEGIO LAPRIDA LOS MOLLES", "-", "12:00", "15:07"],
                ["PUENTE DANDREA LOS MOLLES", "-", "12:10", "15:17"],
                ["ESCUELA RUEDA", "07:44", "12:35", "15:42"],
                ["ESCUELA MASCIAS ASC.", "08:00", "12:51", "15:52"]
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ScheduleTable
              title="SERVICIO 1 IDA SABADOS"
              headers={["PARADA", "MAÑANA", "TARDE"]}
              data={[
                ["LAS VERTIENTES", "09:45", "17:30"],
                ["ALAMO Y E-53 VILLA ANI MI", "10:00", "18:05"],
                ["MARTIN FIERRO", "10:10", "18:12"],
                ["COLEGIO LAPRIDA LOS MOLLES", "10:22", "18:22"],
                ["PUENTE DANDREA LOS MOLLES", "10:32", "18:32"],
                ["ESCUELA RUEDA", "10:52", "18:57"],
                ["ESCUELA MASCIAS ASC.", "11:08", "19:13"]
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ScheduleTable
              title="SERVICIO 1 VUELTA LUNES A VIERNES"
              headers={["PARADA", "1ª VUELTA", "2ª VUELTA", "3ª VUELTA"]}
              data={[
                ["ESCUELA MASCIAS ASC.", "08:30", "13:00", "16:00"],
                ["ESCUELA RUEDA", "08:45", "13:10", "16:10"],
                ["MARTIN FIERRO", "08:55", "13:20", "16:20"],
                ["COLEGIO LAPRIDA LOS MOLLES", "09:07", "13:28", "16:32"],
                ["PUENTE DANDREA LOS MOLLES", "09:17", "13:38", "16:42"],
                ["ALAMO Y E-53 VILLA ANI MI", "09:37", "13:58", "17:02"],
                ["LAS VERTIENTES", "09:53", "14:13", "17:17"]
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ScheduleTable
              title="SERVICIO 1 VUELTA SABADOS"
              headers={["PARADA", "MAÑANA", "TARDE"]}
              data={[
                ["ESCUELA MASCIAS ASC.", "12:00", "20:00"],
                ["ESCUELA RUEDA", "12:15", "20:35"],
                ["MARTIN FIERRO", "12:25", "20:42"],
                ["COLEGIO LAPRIDA LOS MOLLES", "12:37", "20:52"],
                ["PUENTE DANDREA LOS MOLLES", "12:47", "21:02"],
                ["ALAMO Y E-53 VILLA ANI MI", "13:07", "21:27"],
                ["LAS VERTIENTES", "13:23", "21:43"]
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ScheduleTable
              title="SERVICIO 2 IDA LUNES A VIERNES"
              headers={["PARADA", "1ª VUELTA", "2ª VUELTA", "3ª VUELTA"]}
              data={[
                ["LAS VERTIENTES", "12:10", "15:23", "17:57"],
                ["ALAMO Y E-53 VILLA ANI MI", "12:45", "15:58", "18:32"],
                ["MARTIN FIERRO", "12:52", "16:05", "18:39"],
                ["COLEGIO LAPRIDA LOS MOLLES", "13:02", "16:15", "-"],
                ["PUENTE DANDREA LOS MOLLES", "13:12", "16:25", "-"],
                ["ESCUELA RUEDA", "13:32", "16:50", "18:50"],
                ["ESCUELA MASCIAS ASC.", "13:47", "-", "19:00"]
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ScheduleTable
              title="SERVICIO 2 VUELTA LUNES A VIERNES"
              headers={["PARADA", "1ª VUELTA", "2ª VUELTA", "3ª VUELTA"]}
              data={[
                ["ESCUELA MASCIAS ASC.", "14:00", "-", "19:00"],
                ["ESCUELA RUEDA", "14:15", "16:50", "19:15"],
                ["MARTIN FIERRO", "14:25", "17:00", "19:25"],
                ["COLEGIO LAPRIDA LOS MOLLES", "14:37", "17:12", "19:37"],
                ["PUENTE DANDREA LOS MOLLES", "14:47", "17:22", "19:47"],
                ["ALAMO Y E-53 VILLA ANI MI", "15:07", "17:42", "20:07"],
                ["LAS VERTIENTES", "15:23", "17:57", "20:22"]
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ScheduleTable
              title="REFUERZO"
              headers={["PARADA", "1ª VUELTA"]}
              data={[
                ["LAS VERTIENTES", "06:40"],
                ["ALAMO Y E-53 VILLA ANI MI", "07:05"],
                ["MARTIN FIERRO", "07:15"],
                ["COLEGIO LAPRIDA LOS MOLLES", "07:27"],
                ["PUENTE DANDREA LOS MOLLES", "07:37"],
                ["ESCUELA RUEDA", "07:57"],
                ["IPEA A CAMPO", "08:00"]
              ]}
            />
          </Grid>
        </Grid>
      
      </Grid>
    </Grid>
  );
};

export default Transporte;