import React from 'react';
import { 
  Typography, Box, Paper, List, ListItem, ListItemIcon, ListItemText, 
  Card, CardHeader, CardContent, Container, Grid, useTheme, useMediaQuery
} from "@mui/material";
import { Icon } from '@iconify/react';

const tramites = [
  {
    title: 'INSCRIPCIONES',
    items: [
      'Inscripción de nacimientos',
      'Inscripción de matrimonios',
      'Inscripción de defunciones',
      'Inscripción de unión convivencial',
    ],
  },
  {
    title: 'PARTIDAS',
    items: [
      'Solicitud de partidas a través del CIDI',
      'Rectificación de partidas',
    ],
  },
  {
    title: 'AUTORIZACIONES',
    items: [
      'Autorización para inscripción de nacimiento fuera de término',
      'Autorización para inscripción de defunción fuera de término',
    ],
  },
  {
    title: 'LIBRETAS DE FAMILIA',
    items: [
      'Duplicado de libretas de familia',
      'Inscripción de hijos en la libreta de familia',
    ],
  },
  {
    title: 'IDENTIFICACIÓN',
    items: [
      'DNI – Nuevo ejemplar por robo, deterioro, extravío o caducidad',
      'DNI – Recién nacido',
      'DNI – Actualización 5-8 años',
      'DNI – Actualización 14 años',
      'DNI – Cambio de domicilio',
      'DNI – Por errores de confección (reposición)',
      'DNI – Versión digital - "En tu celular"',
      'Pasaporte',
    ],
  },
  {
    title: 'OTROS TRÁMITES',
    items: [
      'Adición de apellido',
      'Reconocimiento',
      'Identidad de género',
      'Opción de nacionalidad argentina',
      'Trámites de inscripción judicial',
      'Inscripción de Divorcio',
    ],
  },
];

const RegistroCivil = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
            <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
              Centro de Documentación
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <List disablePadding>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:map-marker" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="AVDA LOS FRESNOS ESQUINA LOS ACERES" 
                      primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                    />
                  </ListItem>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:phone" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="491212-491456" 
                      primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                    />
                  </ListItem>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:email" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="registrocivil.lagranja@yahoo.com.ar" 
                      primaryTypographyProps={{ 
                        fontSize: { xs: '0.8rem', sm: '0.9rem' },
                        wordBreak: 'break-word'
                      }}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={6}>
                <List disablePadding>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:clock-outline" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Horario de Atención"
                      secondary={
                        <>
                          <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                            Lunes a viernes de 07:00 a 13:30 - Para Realizar Trámites
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                            Lunes a viernes de 07:00 a 14:00 - Para Realizar consultas
                          </Typography>
                        </>
                      }
                      primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                    />
                  </ListItem>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:account" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Responsable" 
                      secondary="Noemi Beatriz Casas"
                      primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                      secondaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ mt: 3, fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
            Trámites
          </Typography>
          <Box sx={{ 
            columnCount: { xs: 1, sm: 2, md: 3 }, 
            columnGap: 2,
            '& > *': { breakInside: 'avoid-column' }
          }}>
            {tramites.map((categoria) => (
              <Card key={categoria.title} elevation={3} sx={{ mb: 2, borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
                <CardHeader
                  title={categoria.title}
                  titleTypographyProps={{ variant: 'h6', fontSize: { xs: '1rem', sm: '1.1rem' } }}
                  sx={{ pb: 1, pt: 2, px: 2 }}
                />
                <CardContent sx={{ pt: 0, pb: 2, px: 2 }}>
                  <List dense disablePadding>
                    {categoria.items.map((item, index) => (
                      <ListItem key={index} disableGutters>
                        <ListItemText 
                          primary={item} 
                          primaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2, mt: 2, borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
            <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
              Las personas interesadas en acceder a un trámite deberán hacerlo de forma presencial en la oficina del Registro Civil o por teléfono solicitar los requisitos para cada trámite.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RegistroCivil;