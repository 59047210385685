import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useParams } from "react-router-dom";

const Turismo = () => {
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container mt={5} justifyContent="center">
      {/* Imagen de fondo */}
      <Grid
        sx={{
          position: "absolute",
          top: 0,
          height: { xs: "300px", md: "400px" }, // Ajuste de altura para pantallas pequeñas y grandes
          zIndex: -300,
          backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/landingmunigr-v2.appspot.com/o/ImagesLanding%2Fturismo.jpeg?alt=media&token=943bca77-d0c4-432e-9228-61b0f1fd964d)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        {/* Barras de colores en el pie */}
        <Grid container sx={{ position: "absolute", bottom: 0, height: "10px" }}>
          <Grid item xs={4} sx={{ backgroundColor: "#163E6A" }} />
          <Grid item xs={4} sx={{ backgroundColor: "#F0891F" }} />
          <Grid item xs={4} sx={{ backgroundColor: "#036B39" }} />
        </Grid>
      </Grid>

      {/* Título principal */}
      <Grid
        item
        xs={10}
        lg={8}
        mt={{ xs: "100px", md: "277px" }} // Ajuste de margen superior para la imagen
        height="fit-content"
        justifyContent="center"
      >
        <Typography
          textAlign="center"
          fontSize={{ xs: 28, md: 36 }}
          fontWeight={600}
          color="#fff"
        >
          Dirección de Turismo, Cultura y Deportes
        </Typography>
      </Grid>

      {/* Tarjetas de información */}
      <Grid
        item
        xs={8}
        height="fit-content"
        mt={10}
        mb={2}
        justifyContent="center"
      >
        <Grid container spacing={3}>
          {/* Tarjeta de información principal */}
          <Grid item xs={12}>
            <Card
              elevation={3}
              sx={{ borderRadius: 4, borderLeft: "8px solid #163e6a" }}
            >
              <CardContent>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  Secretaría de Turismo, Cultura y Deportes
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                      <strong>Secretaria del área:</strong> Claudia Anabela
                      Melgarejo
                    </Typography>
                    <Typography variant="body1">
                      <strong>Directora de cultura:</strong> Morena Garay
                    </Typography>
                    <Typography variant="body1">
                      <strong>Directora de Deportes:</strong> Paula Nayar
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                      <Icon icon="mdi:map-marker" /> Avenida Los Fresnos esquina
                      Los Aceres
                    </Typography>
                    <Typography variant="body1">
                      <Icon icon="mdi:whatsapp" /> +54 9 3525 601475
                    </Typography>
                    <Typography variant="body1">
                      <Icon icon="mdi:instagram" /> @turismo.lagranja
                    </Typography>
                    <Typography variant="body1">
                      <Icon icon="mdi:facebook" /> Secretaría de Turismo,
                      Cultura y Deporte
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ margin: "16px 0" }} />
                <Typography variant="h6" gutterBottom>
                  <Icon icon="mdi:clock-outline" /> Horarios de Atención:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Lunes a viernes:</Typography>
                    <Typography variant="body2">8 a 14hs</Typography>
                    <Typography variant="body2">15:30 a 20:30hs</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Sábados y domingos:</Typography>
                    <Typography variant="body2">9 a 14hs</Typography>
                    <Typography variant="body2">14:30 a 19:30hs</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Tarjetas de Turismo, Cultura, y Deportes */}
          {["Turismo", "Cultura", "Deportes"].map((area) => (
            <Grid item xs={12} key={area}>
              <Card
                elevation={3}
                sx={{ borderRadius: 4, borderLeft: "8px solid #163e6a" }}
              >
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {area}
                  </Typography>
                  <Typography variant="body2" style={{ textAlign: "justify" }}>
                    {area === "Turismo" &&
                      "El turismo es una de las actividades más importantes para la economía de nuestro pueblo. Nos enfocamos en trabajar de manera coordinada promoviendo la autenticidad, la armonía social y la preservación del medio ambiente. Contamos con gran variedad de alojamientos, lugares gastronómicos, comercios, servicios, lugares para visitar, ríos, arroyos, reservas, donde podrán conectarse con la Naturaleza y disfrutar de hermosos momentos en familia y con amigos."}
                    {area === "Cultura" &&
                      "Es intención de la Dirección de Cultura de la localidad de La Granja, en el marco de los objetivos y propuestas definidas por la Secretaría, definir acciones de gestión y comunicación destinadas a la promoción, la valoración y la difusión del patrimonio cultural de nuestro pueblo. Buscamos llevar a cabo nuestras intenciones a través de un trabajo en equipo, lo cual permitirá fortalecer nuestros objetivos, enfocándonos en metas alcanzables."}
                    {area === "Deportes" &&
                      "La Dirección de Deportes considera al deporte y la actividad física como derecho de la población y como factor complementario a la formación integral de las personas. Promovemos los valores del deporte y la actividad física, implementando condiciones que permitan el acceso a su práctica a todo ser humano, ofreciendo oportunidades especiales a jóvenes, niños, adultos mayores y personas con discapacidad."}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Turismo;
