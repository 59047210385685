import { Icon } from "@iconify/react/dist/iconify.js";
import { Grid, IconButton, InputBase, Paper, Pagination, ThemeProvider, createTheme } from "@mui/material";
import foot from "../../assets/foot.png";
import NewsCard from "../../components/NoticiasDestacadas/NewsCard";
import { useEffect, useState } from "react";
import ProductService from "../../services/products.service";

// Custom theme method
const theme = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#163e6a', // Your desired background color
            color: '#ffffff', // Text color for better contrast
            '&:hover': {
              backgroundColor: '#0f2b4a', // Darker shade for hover state
            },
          },
        },
      },
    },
  },
});

const NewsPage = () => {
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 6;

  const fetchNews = async () => {
    try {
      const response = await ProductService.getAllNews();
      console.log(response);
      setNews(response);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const filteredNews = news.filter((item) =>
    item.titulo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedNews = filteredNews.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        justifyContent="center"
        sx={{ padding: "0!important", mt: 10 }}
      >
        <Grid
          sx={{
            position: "absolute",
            top: 0,
            height: "30%",
            zIndex: -300,
            backgroundImage: `url(${foot})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            width: "100%",
          }}
        />
        <Grid
          container
          justifyContent={"center"}
          item
          xs={12}
          md={6}
          mt={4}
          sx={{
            padding: "0!important",
          }}
        >
          <Grid item container xs={12} justifyContent={"center"}>
            <Grid item xs={8}>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Buscar"
                  inputProps={{ "aria-label": "buscar" }}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                  <Icon icon="wpf:search" />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} mt={16} justifyContent={"center"}>
          <Grid item container xs={10} spacing={3} justifyContent={"center"}>
            {paginatedNews.map((el) => (
              <Grid key={el.id} item xs={12} lg={4} container justifyContent={"center"}>
                <NewsCard data={el} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="center" mt={4} mb={4}>
          <Pagination
            count={Math.ceil(filteredNews.length / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
            // Inline styling method using sx prop
            sx={{
              '& .MuiPaginationItem-root.Mui-selected': {
                backgroundColor: '#163e6a', // Your desired background color
                color: '#ffffff', // Text color for better contrast
                '&:hover': {
                  backgroundColor: '#163e6a', // Darker shade for hover state
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default NewsPage;