import React, { useState } from "react";
import {
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardHeader,
  CardContent,
  Container,
  Grid,
  useTheme,
  useMediaQuery,
  Modal,
  Button,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const pdfFiles = [
  {
    name: "Instructivo",
    url: "https://drive.google.com/file/d/1UPZPhTxgiPU-eHt0xxp8AmcnuF38rdL9/preview",
  },
  {
    name: "Requisitos planos de proyecto",
    url: "https://drive.google.com/file/d/16s3g7I57gx1tBZnV38gJQ9buJl1-8Tw0/preview",
  },
  {
    name: "Requisitos planos de relevamiento",
    url: "https://drive.google.com/file/d/1E-b_DGyywBhvwwVsXbqL-h7wKp2XA3Fj/preview",
  },
  {
    name: "Modelo de carátula",
    url: "https://drive.google.com/file/d/1n3UAMU1mAomrPplBGntJ2G98lH5rLRWx/preview",
  },
  {
    name: "Modelo plano municipal",
    url: "https://drive.google.com/file/d/1jTnyExue4VRyKthtzw3TIHhVc5tiR_da/preview",
  },
  {
    name: "Solicitud visación de planos",
    url: "https://drive.google.com/file/d/1zdS5bWls2bGmTTfSTk9P-p96-7WDXu7K/preview",
  },
  {
    name: "Solicitud ambiente municipal",
    url: "https://drive.google.com/file/d/1pfJu22RWYExSpFV9mn8UaDFePhNli_Hf/preview",
  },
  {
    name: "Solicitud habilitación de obras",
    url: "https://drive.google.com/file/d/1bvhAoB0U40H9ykmKwbVzbeSKWHQ15-4v/preview",
  },
  {
    name: "Solicitud final de obra",
    url: "https://drive.google.com/file/d/19X5GrLYfmeQWx-B1gN_iLIv2-W8Xp0n2/preview",
  },
  {
    name: "Resumen de normativas",
    url: "https://drive.google.com/file/d/1Yfnmw5PC_1wVAeNQPKMQ9y6xDzTUm1zs/preview",
  },
];

export default function ObrasPrivadas() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openModal, setOpenModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [selectedPdfName, setSelectedPdfName] = useState("");

  const handleOpenModal = (pdfUrl, pdfName) => {
    setSelectedPdf(pdfUrl);
    setSelectedPdfName(pdfName);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPdf(null);
    setSelectedPdfName("");
  };

  const handleDownload = () => {
    const downloadUrl = selectedPdf.replace("/preview", "/view");
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = `${selectedPdfName}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container item xs={12} sx={{ justifyContent: 'flex-end', pb: 2}}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#163e6a",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#163e6a",
                },
              }}
              onClick={() =>
                window.open(
                  "https://www.lagranja.colegio-arquitectos.com.ar",
                  "_blank",
                  "noopener noreferrer"
                )
              }
            >
              Inicie aquí su expediente
            </Button>
          </Grid>
          <Paper
            elevation={3}
            sx={{
              p: { xs: 2, sm: 3 },
              borderRadius: 4,
              borderLeft: "8px solid #163e6a",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              Obras Privadas
            </Typography>
            <Typography
              variant="body1"
              sx={{ mb: 2, fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              Consulte el mapa de obras privadas y acceda a documentos
              importantes.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper
            elevation={3}
            sx={{
              p: { xs: 2, sm: 3 },
              borderRadius: 4,
              borderLeft: "8px solid #163e6a",
            }}
          >
            <Box
              sx={{
                position: "relative",
                paddingTop: "75%",
                height: 0,
                overflow: "hidden",
              }}
            >
              <iframe
                src="https://mapascordoba.gob.ar/viewer/embed/mapa/451"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: 0,
                }}
                title="Mapa de Obras Privadas"
                allowFullScreen
              />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ mt: 3, fontSize: { xs: "1.1rem", sm: "1.3rem" } }}
          >
            Documentos Importantes
          </Typography>
          <Grid container spacing={2}>
            {pdfFiles.map((file, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  onClick={() => handleOpenModal(file.url, file.name)}
                  elevation={3}
                  sx={{
                    borderRadius: 4,
                    borderLeft: "8px solid #163e6a",
                    cursor: "pointer",
                    transition:
                      "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      transform: "translateY(-5px)",
                      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  <CardHeader
                    title={file.name}
                    titleTypographyProps={{
                      variant: "h6",
                      fontSize: { xs: "14px" },
                    }}
                    sx={{ pb: 1, pt: 2, px: 2 }}
                  />
                  <CardContent sx={{ pt: 0, pb: 2, px: 2 }}>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemIcon sx={{ minWidth: 36 }}>
                          <Icon
                            icon="mdi:file-pdf-box"
                            width="24"
                            height="24"
                            style={{ color: "red" }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Ver PDF"
                          primaryTypographyProps={{
                            fontSize: { xs: "0.8rem", sm: "0.9rem" },
                          }}
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="pdf-modal"
        aria-describedby="pdf-viewer"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 800,
            bgcolor: "background.paper",
            borderRadius: "16px",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          {selectedPdf && (
            <>
              <Typography variant="h6" component="h2" gutterBottom>
                {selectedPdfName}
              </Typography>
              <iframe
                src={selectedPdf}
                style={{
                  width: "100%",
                  height: isMobile ? "60vh" : "70vh",
                  border: "none",
                }}
                title="PDF Viewer"
                allowFullScreen
              />
              <Box
                sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Button variant="contained" onClick={handleCloseModal}>
                  Cerrar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDownload}
                >
                  Descargar PDF
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Container>
  );
}
