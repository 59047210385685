import React from 'react';
import { 
  Typography, Box, Paper, List, ListItem, ListItemIcon, ListItemText, 
  Card, CardHeader, CardContent, Container, Grid, useTheme, useMediaQuery
} from "@mui/material";
import { Icon } from '@iconify/react';

const miembros = [
  { nombre: 'ROMAN MAYER', cargo: 'PRESIDENTE del C. D.', email: 'graficapampasc@gmail.com', partido: 'JUNTOS POR EL CAMBIO' },
  { nombre: 'SARTOR MARA', cargo: 'SECRETARIA 1º', email: '', partido: 'JUNTOS POR EL CAMBIO',email: 'sartormara37@gmail.com' },
  { nombre: 'ESTEFANIA ROMANO', cargo: 'VICEPRESIDENTA 1º', email: 'romanestefaniasoledad14@gmail.com', partido: 'JUNTOS POR EL CAMBIO' },
  { nombre: 'HUGO PETIT', cargo: '', email: 'Hugo_petit@hotmail.com', partido: 'JUNTOS POR EL CAMBIO' },
  { nombre: 'CARLOS AMBROSICH', cargo: 'VICEPRESIDENTE 2º', email: 'carlosambrosich@hotmail.com', partido: 'HACEMOS POR CORDOBA' },
  { nombre: 'CANDELARIA MOLINA', cargo: 'SECRETARIA 2º', email: 'cmolinalagranja@gmail.com', partido: 'HACEMOS POR CORDOBA' },
  { nombre: 'NOELIA PERRIN', cargo: '', email: 'noe_perrin104@hotmail.com', partido: 'ENCUENTRO VECINAL CORDOBA' },
];

const ConsejoDeliberante = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
            <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
              CUERPO LEGISLATIVO DE LA MUNICIPALIDAD DE LA GRANJA
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <List disablePadding>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:account-group" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Concejo Deliberante" 
                      primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                    />
                  </ListItem>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:clock-outline" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="SESION ORDINARIA: DIA MARTES A LAS 19 HS" 
                      primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={6}>
                <List disablePadding>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon icon="mdi:office-building" width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText
                      primary="OFICINA DEL CONCEJO DELIBERANTE"
                      secondary={
                        <>
                          <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                            SECRETARIA: VALERIA SOLEDAD VILTE
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                            ATENCION AL PUBLICO: LUNES A JUEVES DE 8 A 12 HS.
                          </Typography>
                        </>
                      }
                      primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ mt: 3, fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
            Miembros del Concejo Deliberante
          </Typography>
          <Box sx={{ 
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr' },
            gap: 2
          }}>
            {miembros.map((miembro, index) => (
              <Card key={index} elevation={3} sx={{ borderRadius: 4, borderLeft: '8px solid #163e6a' }}>
                <CardHeader
                  title={miembro.nombre}
                  subheader={miembro.cargo}
                  titleTypographyProps={{ variant: 'h6', fontSize: { xs: '1rem', sm: '1.1rem' } }}
                  subheaderTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                  sx={{ pb: 1, pt: 2, px: 2 }}
                />
                <CardContent sx={{ pt: 0, pb: 2, px: 2 }}>
                  <List dense disablePadding>
                    <ListItem disableGutters>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <Icon icon="mdi:email" width="20" height="20" />
                      </ListItemIcon>
                      <ListItemText 
                        primary={miembro.email} 
                        primaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                      />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <Icon icon="mdi:flag" width="20" height="20" />
                      </ListItemIcon>
                      <ListItemText 
                        primary={miembro.partido} 
                        primaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ConsejoDeliberante;