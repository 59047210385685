import { Icon } from "@iconify/react/dist/iconify.js";
import { Grid } from "@mui/material";
import styles from "./styles.css";

const StaticCard = ({ data, setCardSelected }) => {
  return (
    <Grid item xs={12} lg={3} onClick={() => setCardSelected(data)} sx={{ cursor: 'pointer'}}>
      <div class="ag-courses_box">
        <div class="ag-courses_item">
          <div class="ag-courses-item_link">
            <div class="ag-courses-item_bg"></div>

            <div class="ag-courses-item_title">
              <Icon
                class="ag-courses-itemi_icon"
                icon={data.icon}
                width={80}
                height={80}
              />
            </div>

            <p class="ag-courses-item_date-box">{data.name}</p>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default StaticCard;
