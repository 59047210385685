import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";

const NewsCard = ({ data }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleRedirect = () => {
    navigate(`/news/${data._id}`);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const cardStyle = {
    backgroundColor: "#fff",
    borderRadius: "0.5rem",
    boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    overflow: "hidden",
    width: "90%",
    maxWidth: "32rem",
    cursor: "pointer",
    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
    transform: isHovered ? "scale(1.03)" : "scale(1)",
    boxShadow: isHovered 
      ? "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"
      : "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  };

  return (
    <div
      onClick={handleRedirect}
      style={cardStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleRedirect();
        }
      }}
      role="article"
      aria-label={`News article: ${data.titulo}`}
    >
      <div style={{ position: "relative" }}>
        <img
          src={data?.imagenes[0] || '/placeholder.svg?height=240&width=400'}
          alt={data.titulo || "News article image"}
          style={{ 
            width: "100%", 
            height: "240px", 
            objectFit: "cover", 
            aspectRatio: "400/240",
            transition: "transform 0.3s ease-in-out",
            transform: isHovered ? "scale(1.05)" : "scale(1)",
          }}
          width="400"
          height="240"
        />
        <div
          style={{
            position: "absolute",
            top: "1rem",
            left: "1rem",
            backgroundColor: "#163E6A",
            color: "#fff",
            padding: "0.25rem 0.75rem",
            borderRadius: "0.25rem",
            fontSize: "0.875rem",
            fontWeight: "500",
          }}
        >
          {data.categoria}
        </div>
      </div>
      <div style={{ padding: "1rem" }}>
        <h3 style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
          {data.titulo}
        </h3>
      </div>
    </div>
  );
};

export default NewsCard;