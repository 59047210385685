import {
  Box,
  Container,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import ObrasPrivadas from "./obrasPrivadas";
import WasteCollection from "./residuos";
import foot from "../../assets/banner5.png"; // Ruta correcta de la imagen

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: { xs: 2, md: 3 } }}>{children}</Box>}
    </div>
  );
}

const Vecino = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tabsStyles = {
    '& .MuiTab-root': {
      '&:hover': {
        backgroundColor: 'transparent',
        fontWeight: 'bold',
      },
      '&.Mui-selected': {
        fontWeight: 'bold',
        color: '#163e6a',
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#163e6a',
    },
  };

  return (
    <Grid container mt={5} justifyContent="center">
      {/* Imagen de fondo */}
      <Grid
        sx={{
          position: "absolute",
          top: 0,
          height: { xs: "300px", md: "400px" },
          zIndex: -300,
          backgroundImage: `url(${foot})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        {/* Barras de colores en el pie */}
        <Grid container sx={{ position: "absolute", bottom: 0, height: "10px" }}>
          <Grid item xs={4} sx={{ backgroundColor: "#163E6A" }} />
          <Grid item xs={4} sx={{ backgroundColor: "#F0891F" }} />
          <Grid item xs={4} sx={{ backgroundColor: "#036B39" }} />
        </Grid>
      </Grid>

      {/* Título */}
      <Grid item xs={10} mt={{ xs: "200px", md: "277px" }} textAlign="center">
        <Typography
          fontSize={{ xs: 24, md: 36 }}
          fontWeight={600}
          color={"#fff"}
        >
          Servicios al Vecino
        </Typography>
      </Grid>

      {/* Tabs y contenido */}
      <Grid
        item
        xs={12}
        mt={10}
        mb={10}
        justifyContent="center"
        sx={{
          minHeight: { xs: "calc(100vh - 300px)", md: "calc(100vh - 400px)" }, // Altura dinámica según el viewport
        }}
      >
        <Container maxWidth="lg">
          <Paper elevation={3} sx={{ borderRadius: 4, overflow: "hidden" }}>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
                display: "flex",
                flexDirection: { xs: "column", md: "row" }, // Cambia a columna en móviles
                minHeight: { xs: "auto", md: "calc(100vh - 400px)" },
                marginBottom: { xs: 4, md: 0 }, // Ajuste de espacio en pantallas pequeñas
              }}
            >
              <Tabs
                orientation="vertical" // Cambia a vertical en todas las pantallas
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Guía de Trámites tabs"
                sx={{
                  ...(isDesktop
                    ? {
                      borderRight: 1,
                      borderColor: "divider",
                      minWidth: "200px",
                      "& .MuiTabs-indicator": {
                        left: 0,
                        right: "auto",
                      },
                    }
                    : {
                      borderBottom: 1,
                      borderColor: "divider",
                      "& .MuiTabs-flexContainer": {
                        flexDirection: "column", // Establece la dirección vertical
                      },
                      "& .MuiTab-root": {
                        flexGrow: 1, // Cada tab ocupa el ancho completo
                      },
                    }),
                  ...tabsStyles,
                }}
              >
                <Tab label="Obras Privadas" />
                <Tab label="Recolección de Residuos" />
                <Tab label="Ambiente" />
              </Tabs>
              <Box sx={{ flexGrow: 1, overflow: "auto" }}>
                <TabPanel value={value} index={0}>
                  <ObrasPrivadas />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <WasteCollection />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Typography variant="h6">Ambiente</Typography>
                  <Typography>Esta sección está en desarrollo.</Typography>
                </TabPanel>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Vecino;
