import React, { useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ProductService from "../../services/products.service";
import ProductSkeleton from "./ProductSkeleton";
import NewsCard from "./NewsCard";

const SwiperContainer = ({ items, renderItem }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  return (
    <Swiper
      modules={[Navigation, Pagination]}
      navigation
      pagination={{ clickable: true }}
      spaceBetween={10}
      slidesPerView={4}
      breakpoints={{
        0: { slidesPerView: 1 },
        650: { slidesPerView: 2 },
        1100: { slidesPerView: 3 },
      }}
      style={{ width: isMobile ? "450px" : "100%" }}
    >
      {items.map((el) => (
        <SwiperSlide
          key={el.id}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {renderItem(el)}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const NoticiasDestacadas = () => {
  const [loading, setLoading] = useState(true);
  const [destacadas, setDestacadas] = useState([]);  

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    setLoading(true);
    const fetchNews = async () => {
      try {
        const allNews = await ProductService.getAllNews();
        const filteredNews = allNews.filter((news) => news.destacado);
        setDestacadas(filteredNews);
      } catch (error) {
        console.error("Error al obtener las noticias:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  return (
    <Grid
      item
      container
      xs={12}
      id="newProductsContainer"
      sx={{ justifyContent: "center", margin: "20px 0", padding: "20px 0" }}
    >
      <Grid
        item
        xs={10}
        container
        flexDirection="row"
        sx={{ minHeight: "350px" }}
      >
        {loading ? (
          <ProductSkeleton />
        ) : isMobile ? (
          <SwiperContainer
            items={destacadas}
            renderItem={(news) => <NewsCard data={news} />}
          />
        ) : (
          <>
            {destacadas.slice(0, 3).map((news) => (
              <Grid
                item
                container
                justifyContent={"center"}
                xs={12}
                sm={6}
                md={4}
                key={news.id}
              >
                <NewsCard data={news} />
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default NoticiasDestacadas;
